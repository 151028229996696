import React from 'react'
import Layout from '../components/Layout'
import SEO from "../components/SEO";

const NotFoundPage = () => (
    <Layout>
        <SEO
            title={"Not Found" }
            description={"404 Not Found"}
        />
        <section className="w-full px-4 md:px-6 py-6 container mx-auto">
            <h1 className="md:text-4xl font-semibold leading-10 text-3xl">Not Found</h1>
            <p className="text-gray-700 leading-6">The page you are looking for does not exist. Please try again by visiting the home page or contact us to help you find what you are looking for.</p>

        </section>
    </Layout>
)

export default NotFoundPage
